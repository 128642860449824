import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { FeaturedProjectsTitleProps } from './FeaturedProjectsTitle.props';
import styles from './FeaturedProjectsTitle.module.scss';

const FeaturedProjectsTitle = ({ title, colorVariant }: FeaturedProjectsTitleProps) => {
  if (!title) return null;

  return (
    <Stack
      direction="row"
      sx={{
        mb: { xs: 16, lg: 24 },
        mt: { xs: 35, lg: 30 },
        alignItems: 'flex-end',
      }}
      spacing={16}
    >
      <Typography
        variant="overline"
        sx={{ color: colorVariant === 'light' ? 'grey.500' : 'common.white' }}
        className={styles.FeaturedProjectTitle}
      >
        {title}
      </Typography>
      <Box className={styles.FeaturedProjectTitleDivider} />
    </Stack>
  );
};

export default FeaturedProjectsTitle;
